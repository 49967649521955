import React from 'react'

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Hi 👋, I'm Jerry Barrows-Fitzgerald</h1>
      </header>
      <p>
        I'm from Las Vegas, Nevada, USA. I have enjoyed the last thirteen years
        working at a small publishing company, beginning in the contract
        processing/data entry department. Over the years, I gained knowledge and
        earned promotions to department supervisor, data analyst, and finally
        systems manager and second-in-command of the company's production
        department.
      </p>
      <p>
        I graduated from Code Fellows, an intensive full stack software
        development boot camp program, in August 2023 with a specialization in
        Python. I have also earned a bachelor's degree from Western Governor's
        University in Software Development and have been using the skills
        learned there to transition my employer from a paper-based company to
        one that thrives by making data-driven decisions.
      </p>
      <p>I'd like to be your next full stack engineer.</p>
      <div className="content">
        <ul className="actions ">
          <li>
            <a
              href="https://www.linkedin.com/in/jbarrowsfitzgerald/"
              className="button scrolly icon fa-linkedin"
            >
              LinkedIn
            </a>
            <a
              href="https://github.com/jbarrfitz"
              className="button scrolly icon fa-github"
            >
              GitHub
            </a>
            <a
              href="/resume.pdf"
              target="_blank"
              className="button scrolly icon fa-file"
            >
              Resume
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Banner
